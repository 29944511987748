.title {
    font-size: 42px; 
    background: linear-gradient(to right, #ffffff 0%, #C6B8B5 100%); 
}

.description {
    font-size: 20px;
    line-height: 1.8 !important;
    margin-top: 1rem !important;
}

.home-content {
    display: flex;
    justify-content: center;  
    margin-left: 10% !important;
    margin-right: 12% !important;
    padding-bottom: 5rem !important;
    padding-top: 6rem !important; 
}

.home-buttons {
    color: #FFB100 !important;
    border-color: #FFB100 !important;
    border-width: medium !important;
    font-weight: 600 !important;
    font-size: 18px !important;
    border-radius: 8px !important;
    padding: 13px !important;  
    margin-right: 20px !important;
} 

.home-buttons:hover {
    background: #FFB100 !important;
    color: white !important;
}

.image {
    display: flex;
    justify-content: flex-start; 
    flex-grow: 2;
    width: 32vw !important; 
    margin-left: 8rem!important;
    max-width: 32vw; 
    object-fit: fill !important;
}

.content-text { 
    text-align: left !important; 
    color: #404040 !important;
}

.buttons-div {
    display: flex !important;
    flex-direction: row;
    justify-content: flex-start;
    margin-top: 2rem !important;
    margin-bottom: 3rem !important;
} 

.texts-div {
    margin-top: 4rem !important;
}

.ul-features {
    list-style-type: circle !important;
}
 
.container { 
  width: 100%; 
  margin: 0 auto;
}

/*img:not(.png) {*/
/*  width: 100%;*/
/*}*/

.workflow-header {
    margin-top: 4rem !important;
    margin-bottom: 1rem !important;
    font-size: 24px;
    font-weight: 600;
    display: flex;
    flex-direction: row;
    justify-content: center; 
    /*color: #FFB100 !important;*/
    background: linear-gradient(to right, #FFB100 45%, #FFFFFF 80%);
    -webkit-background-clip: text;
	-webkit-text-fill-color: transparent;
}

h1 {
    margin-top: 3em !important;
    color: #605958 !important;
}