.form-button {
    border-radius: 25px !important;
    background: #FFB100 !important;
}

.form-button:hover {
    opacity: 0.8;    
}

.button-text { 
    margin: 5px 20px 5px 20px !important; 
    font-size: 14px !important;
    font-weight: 600 !important; 
    color: white !important;
}

.dialog-button {  
    color: #FFB100 !important;
    border-radius: 25px !important;
    font-size: 14px !important;
    font-weight: 600 !important;
    border-color: #FFB100 !important; 
}

.dialog-button-text {
    margin: 3px 8px 3px 8px !important; 
}

.dialog-button:hover {
    background: #FFB100 !important;
    color: white !important;
}
 


.button-box-position {
    padding-bottom: 2rem !important;
    padding-top: 2rem !important;
}
 
.button-position {
    display: flex;  
    justify-content: center;
    gap: 20px !important;
}

.table {
    background-color: #EFEFEF !important; 
    min-height: 62vh; 
    margin-bottom: 2rem!important;
    display: flex;
    flex-direction: column;
    justify-content: space-between; 
    overflow: auto;
}

.table-position {
    padding-right: 20%;
    padding-left: 20%;
}

.table-header {
   display: flex;
   justify-content: flex-start; 
   font-weight: 600 !important;
   padding-top: 20px !important;
   padding-left: 20px !important;
   padding-bottom: 5px !important;
   font-size: 20px;
   color: #605958 !important;
   
}

.MuiInputBase-root {
     border-radius: 15px !important; 
}   

.MuiChip-label {
    font-family: 'Inter Tight' !important;
}

/*.MuiButton-root {*/
/*    margin: 10px 0px 10px 0px !important;*/
/*}*/
 
/*.MuiButton-root:hover {*/
/*    background-color: #FFB100 !important;*/
/*    opacity: 0.9;*/
/*}*/

.MuiDialogActions-root {
    padding-bottom: 1rem !important;
}

.MuiAlert-message {
    padding-top: 11px !important;
}

.table-cell { 
    display: block;   
    text-overflow: ellipsis !important;
    white-space: nowrap !important;
    overflow: hidden;
}