.card-content {
    display: flex;
    flex-direction: row;
    justify-content: flex-start; 
    align-content: flex-start;
    white-space: nowrap !important;
}
 
.card-header { 
    text-align: left !important;
    font-size: 24px !important;
    color: #A1A1A1 !important; 
}

.card-subtext { 
    text-align: left !important;
    font-size: 16px !important;
    color: #A1A1A1 !important; 
}