.download-header {
    margin-top: 5rem !important;
    margin-left: 4% !important;
    font-weight: 600 !important;
    font-size: 40px !important;
    color: #525252 !important;
}

.home-link {
    color: blue !important;
}

.home-link:hover {
    color: blue !important;
}