.content-box {
    height: 64vh;
    border: 4;
    border-color: white;
    border-radius: 10px;
    margin-top: 0.5rem;
    overflow: auto;
}
 
.content-header {
    font-size: 24px;
    font-weight: bolder;
    padding-top: 1rem;
    padding-left: 1rem;
    color: #605958;
}

.sub-header {
    font-size: 16px;
    font-weight: bolder;  
    color: #7C7C7C;    
}

.sub-header-alt {
    padding-left: 1rem;
    font-size: 16px;
    font-weight: bolder;  
    color: #7C7C7C;    
}

.content-accordion {
    padding: 1rem; 
}