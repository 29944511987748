@import "rsuite/dist/rsuite.css";
 
.rs-input {
    font-size: 16px;
}

.rs-input-group.rs-input-group-inside {
    height: 3.8vh; 
}

.rs-picker-popup .rs-calendar {
    min-width: 350px;
}