.header {
    color: #FFB100;
    font-weight: 500 !important;
    padding-top: 2rem !important;
    padding-bottom: 5rem !important;
    font-size: 50px !important;
    text-align: center;
}

.keyword-table {
    padding-left: 20% !important;
    padding-right: 20% !important; 
}