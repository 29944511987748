.header-tag {
    font-size: 20px;
    font-weight: 600;
    padding-bottom: 3px;
}

.filter-button {
    background: #605958 !important; 
}

.clear-button {  
    color: #605958 !important;  
    font-size: 14px !important;
    font-weight: 600 !important;
    border-color: #605958 !important; 
    border-width: 2px !important;
} 

.clear-button:hover {
    background-color: #605958 !important;
    color: white !important;
} 

.Mui-disabled {
    color: #D9D9D9 !important;
    border-color: #D9D9D9 !important;
}
 
.search-button {
  height: 100%;
  margin-bottom: 3px !important;
  border-radius: 25px !important;
  background: #FFB100 !important;
}

.search-button:disabled { 
  background: rgba(0, 0, 0, 0.12) !important;
}

/*.form-button {*/
/*    border-radius: 25px !important;*/
/*    background: #FFB100 !important;*/
/*}*/

/*.form-button:hover {*/
/*    opacity: 0.8;    */
/*}*/