.MuiDataGrid-columnHeader:focus-within {
    outline: none !important;
}  

.id-typography {
    font-size: 0.85rem !important;
    color: #5468FF !important;
    font-weight: bold !important;
    text-overflow: ellipsis !important;
    overflow: hidden !important;    
    white-space: nowrap;
}

.id-typography:hover {
    cursor: pointer !important;
    text-decoration: underline;
    color: blue !important; 
}


.type-typography {
    font-size: 0.85rem !important;
    color: #000000 !important;
    font-weight: bold !important;
    text-overflow: ellipsis !important;
    overflow: hidden !important;    
}

.type-typography:hover {
    cursor: pointer !important;
    text-decoration: underline;
    color: blue !important;     
}

.typography-group {
    font-size: 0.85rem !important;
    color: #000000 !important; 
    text-overflow: ellipsis !important;
    overflow: hidden !important;  
    white-space: nowrap; 
}

.typography-group:hover {
    cursor: pointer !important;
    text-decoration: underline;
    color: blue !important;       
}

.typography-single {
    font-size: 0.85rem !important;
    color: #000000 !important; 
    text-overflow: ellipsis !important;
    overflow: hidden !important; 
    white-space: nowrap;
    flex-grow: 1;         
}

.typography-single:hover {
    cursor: pointer !important;
    text-decoration: underline;
    color: blue !important;       
}
  
.cell-tooltip{
    color: white !important;
    font-size: 14px !important;
}

.typography { 
    font-size: 0.85rem !important;  
    color: #000000 !important;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box; 
    -webkit-box-orient: vertical
}   

.typography-copyable {
    font-size: 0.85rem !important;  
    color: #000000 !important;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box; 
    -webkit-box-orient: vertical    
}

.typography-copyable:hover {
    cursor: pointer !important;
    text-decoration: underline;
    color: blue !important;
}

.video {
    object-fit: cover !important;
    overflow: hidden;
    padding-right: 5px !important;
}