.position {
    display: flex;
    justify-content: space-between;
    flex-direction: row;
    align-items: center;
}

.menu-header {
    display: flex; 
    flex-direction: row; 
    align-items: center; 
}

.logo {   
    margin-right: 25px !important;
} 
 
.logo:hover {  
    cursor: pointer; 
}

.menu-subheader {
    color: white !important;
    font-size: 15px !important; 
    padding: 10px !important;
    font-weight:600 !important; 
    border-radius: 25px !important; 
}

.menu-subheader:hover { 
    cursor: pointer;  
    background-color: rgba(255, 255, 255, 0.1) !important;
    /*color: #FFB100 !important;*/
} 

.MuiCollapse-container {
  width: 0px;
  height: 0px;
 transition-property: width !important;
}

.MuiCollapse-entered {
  /*width: 100%*/
  height: 100% !important;
}

.MuiCollapse-hidden {
  width: 0px;
  height: 0px !important;
}
 