.bookmark-button {
    border-radius: 35px !important;
    background: #605958 !important;
}

.bookmark-button:hover {
    opacity: 0.8;    
}

.bookmark-button:disabled { 
    background: rgba(0, 0, 0, 0.12) !important;  
}

.bookmark-button-text { 
    margin: 3px 10px 3px 10px !important;
    font-size: 15px !important;
    font-weight: 600 !important; 
    color: white !important;
}

.bookmark-field-header {
   font-weight: 500 !important;
   padding: 4px 4px 8px 4px;
   font-size: 18px !important;
}

.MuiDialogActions-root {
    display: flex;
    flex-direction: row;
    justify-content: center !important;
    gap: 10px !important;
} 