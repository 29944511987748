 .MuiListItemText-root {
   display: block;
   overflow-x: hidden !important;
   white-space: nowrap !important;
   text-overflow: ellipsis !important;    
}
 
.MuiList-root {
    padding-top: 0px !important;
} 
 
.list {
    display: flex;
    flex-direction: column;
    justify-content: space-between !important;
    column-gap: 10px !important;
}

.left-column {
    display: flex;
    flex-direction: column; 
    column-gap: 10px !important;
    margin-top: 1rem !important;
    margin-bottom: 1rem !important; 
} 