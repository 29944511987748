.header {
    color: #605958;
    font-weight: 600 !important;
    padding-top: 2rem !important;
    font-size: 50px !important;
}

.grouptag-table {
    padding-left: 20% !important;
    padding-right: 20% !important; 
}