.chatlog {
    position: relative;
    height: calc(64vh - 48px) !important;
    border-color: white;
    border-radius: 0 0 10px 10px;
    overflow: auto;
    flex-grow: 1;
}

::-webkit-scrollbar {
    width: 0.5em;
    height: 0.5em;
    background-color: #D8D8D8;
    border: 1px solid #D8D8D8;
    border-radius: 20px; 
}

::-webkit-scrollbar-thumb {
    background-color: #605958;
    border: 1px solid #605958; 
    border-radius: 20px; 
}

.chatlog-appbar {
    display: flex !important;
    flex-direction: row !important;
    justify-content: space-between !important;
    align-items: center;
    flex-grow: 1;
}

.chatlog-header { 
    font-size: 16px !important;
    font-weight: bold !important;
}

.chatlog-date {
    display: flex;
    flex-direction: row !important;
    justify-content: center !important;
    font-size: 15px;
    color: #A1A1A1 !important;
    font-weight: 600 !important;
    padding-top: 10px !important;
}

.chatlog-startmessage {
    padding-left: 1rem !important;
    font-size: 16px;
    color: #A1A1A1 !important; 
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
    /*position: fixed;*/
    background-color: white !important;
}

.chatlog-avatar {
    padding-left: 1rem !important;
    display: flex;
    justify-content: flex-start !important;
}

.chatlog-position {
    display: flex;
    flex-direction: row !important;
    justify-content: flex-start !important;
    padding-bottom: 1rem !important;
}

.chatbubble-topleft {
    clear: both;
    float:left;
    border-radius: 15px 15px 15px 0 !important;   
    color: white; 
    /*background-color: #4992FF; // Highlights the hit message */
    padding: 5px;
    margin-left: 1rem !important;
    margin-bottom: 3px !important;
    /*flex-grow: 1; */
    min-width: 4vw !important;
    max-width: 35vw !important;
    /*height: auto;*/
}
 
.chatbubble-middle {
    clear: both;
    float:left;
    border-radius: 0 15px 15px 0 !important;  
    /*flex-grow: 1; */
    min-width: 4vw !important;
    max-width: 35vw !important;
    /*height: auto;*/
    color: white; 
    padding: 5px;
    margin-left: 1rem !important;
    margin-bottom: 3px !important;
}

.chatbubble-bottomleft {
    clear: both;
    float:left;
    border-radius: 0 15px 15px 15px !important;  
    /*flex-grow: 1; */
    min-width: 4vw !important;
    max-width: 35vw !important;
    /*height: auto;*/
    color: white; 
    padding: 5px;
    margin-left: 1rem !important;
    margin-bottom: 3px !important;
}
 
.message-username {
    color: white;
    font-weight: 600 !important;
    padding-left: 5px; 
    display: flex;
    flex-direction: row;
    justify-content: space-between; 
    align-items: center;
}

.message-username:hover {
    cursor: pointer !important;
    text-decoration: underline;
    color: #FFB100 !important;   
}

.message-sender-id {
    color: white;
    font-weight: 600 !important;
    padding-left: 5px; 
    display: flex;
    flex-direction: row;
    justify-content: space-between; 
    align-items: center;
}

.message-sender-id:hover {
    cursor: pointer !important;
    text-decoration: underline;
    color: #FFB100 !important;   
}

.message-username-channel {
    color: white;
    font-weight: 600 !important;
    padding-left: 5px; 
    display: flex;
    flex-direction: row;
    justify-content: space-between; 
    align-items: center;  
}

.message-menu {
    display: flex;
    flex-direction: row !important;
    justify-content: space-between !important; 
}

.message-content { 
    padding: 5px;    
    width: auto;
}

.message-timestamp {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    padding-right: 5px;
}

.message-media {
    padding: 5px;
}

.message-caption {
    margin-left: 5px;
    margin-right: 5px;
    /*width: 18vw; */
    width: auto;
} 

.root-message-caption {
    padding-left: 5px;
    padding-right: 5px; 
}

.media-refresh-link-post { 
    font-weight: bold !important;
    color: black;
    margin-left: 5px; 
    margin-right: 5px;
}

.media-refresh-link-post:hover {
    color: #FFB100;
    cursor: pointer;
}

.media-refresh-link { 
    font-weight: bold !important;
    color: white;
    margin-left: 5px; 
    margin-right: 5px;
}

.media-refresh-link:hover {
    color: #FFB100;
    cursor: pointer;
}

.favourite-icon {
    color: #FFB100 !important;
    margin-left: 5px !important;
} 

.favourite-icon:hover {
    background-color: transparent !important;
}

.root-avatar {
    padding-left: 1rem !important;
    padding-top: 1.2rem !important; 
}

.root-displayname {
    font-weight: 600 !important;
    padding-left: 1rem !important;
    padding-top: 1rem !important; 
}

.root-displayname:hover {
    cursor: pointer !important;
    text-decoration: underline;
    color: blue !important;   
}

.root-id { 
    font-weight: 600 !important;
    padding-top: 1rem !important; 
    padding-left: 0.2rem !important;
}

.root-id:hover {
    cursor: pointer !important;
    text-decoration: underline;
    color: blue !important;   
}

.root-datestring {
    padding-left: 1rem !important;  
    color: grey !important;
}

.root-content {
    padding: 1rem 0 1rem 1rem !important; 
}

.link-button {  
    color: white !important;  
    font-size: 14px !important;
    font-weight: 600 !important; 
    background-color: #FFB100 !important;
} 

.root-message-menu {
    display: flex;
    flex-direction: row !important;
    justify-content: space-between !important;  
}

.reply-message {
    margin-left: 5px;
    margin-right: 5px;
    padding: 5px; 
    background-color: rgba(192,192,192,0.3) !important;
    border-color: #FFB100 !important; 
}

.success-snackbar { 
    color: #4e9250 !important;
    background-color: #edf7ed !important;
}

.error-snackbar {
    color: #7b4443 !important;
    background-color: #fdeded !important;
}

.pin-button {
    color: white !important;
    border: 1px solid white !important;
    border-color: white !important;
    border-radius: 25px !important;
    margin: 3px 5px 3px 0 !important; 
} 

.refresh-button {
    color: white !important;
    border: 1px solid #605958 !important;
    border-color: #605958 !important; 
    margin: 3px 5px 3px 0 !important; 
}  