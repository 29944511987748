.search-bar-position {
    display: flex;
    justify-content: space-between;
    padding-bottom: 1rem !important; 
}

.search-results {
    display: flex;
    justify-content: flex-end; 
    padding-bottom: 1rem;
}

.MuiTableContainer-root {
    border: 1px solid #efefef !important; 
    border-radius: 8 !important;
}

.MuiTablePagination-selectLabel {
    font-size: 16px !important;
}

.MuiTablePagination-displayedRows {
    font-size: 16px !important;
}
 
.loader {
    display: flex;
    justify-content: center
}

.table-cell { 
    display: block;
    text-decoration: none !important;
    font-size: 14px !important; 
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
}