.notistack-MuiContent-success {
    background-color: #edf7ed;
    opacity: 0.8;
    color: #1e4620;
}

.notistack-MuiContent-error {
    background-color: #fdeded;
    color: #5f2120;
    opacity: 0.8;
}

.filter-header {
    padding-left: 0.8rem;
}

.filter-sub {
    padding-left: 0.8rem;
    padding-right: 0.8rem;
}
 